<template>
  <div>

    <!-- Settings button-->
    <button
      v-if="settings"
      @click="$emit('show-settings-modal')"
      class="btn btn-mini btn-white pull-right"
    >
      <i class="fa fa-gear"></i>
    </button>

    <!-- Searchfield -->

    <div class="pull-right" v-if="search">
      <div class="input-group m-b" style="width:150px;">
      <span class="input-group-btn">
        <button type="button"
                class="btn btn-primary"
                @click="searchFor">
          <i class="fa fa-search"></i>
        </button>
      </span>
        <input type="text"
               @keyup.enter="searchFor"
               placeholder="Zoeken"
               v-model="searchQuery"
               class="form-control search-validation">
      </div>
    </div>

    <!-- Toolbar slot-->
    <div class="pull-right" style="padding-right: 3px;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    settings: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      required: true
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: ''
    }
  },
  methods: {

    /** Emits our search query to the main Table component so the vmodel value updates. */
    searchFor () {
      this.$emit('input', this.searchQuery)
    }
  },
  watch: {
    /** The vmodel input for the searchquery */
    value () {
      this.searchQuery = this.value
    }
  }
}
</script>

<style scoped>
</style>
