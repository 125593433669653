<template>
  <div>
    <TableSettings :columns="settingColumns"
                   ref="tableSettings"
                   v-model="columnSelection"
    ></TableSettings>
    <TableActionBar v-model="searchQuery"
                    :search="search"
                    :settings="settingFields.length > 0"
                    @show-settings-modal="showSettingsModal"
    >
      <slot name="toolbar"></slot>
    </TableActionBar>
    <TableContent :records="records"
                  :columns="visibleColumns"
                  :actions="actions"
                  :selectable="selectable"
                  :loading="loading"
                  v-model="selected"
                  v-if="records"
                  @action="emitAction"
    ></TableContent>
    <TablePaginator :current-page="currentPageCount"
                    :max-page="maxPageCount"
                    :selected="selected"
                    :selectable="selectable"
                    @previous="toPreviousPage"
                    @next="toNextPage"
                    @go-to="goToPage"
    ></TablePaginator>
  </div>
</template>

<script>
import TableSettings from './TableSettings'
import TableActionBar from './TableActionBar'
import TableContent from './TableContent'
import TablePaginator from './TablePaginator'
import { validateKeys } from '@/utils/helpers'
import $ from 'jquery'

export default {
  name: 'Table',
  props: {
    value: {
      default: null
    },
    endpoint: {
      type: String,
      required: true
    },
    settingFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    search: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      required: true,
      validator: columns => {
        let valid = true
        for (const column of columns) {
          const allowedKeys = ['title', 'key', 'visible', 'renderComponent']
          valid = validateKeys(column, 'columns property', allowedKeys)
        }
        return valid
      }
    },
    actions: {
      type: Array,
      required: false,
      validator: actions => {
        let valid = true
        for (const action of actions) {
          const allowedKeys = ['key', 'type', 'icon', 'title', 'conditionalKey', 'inverseCondition']
          valid = validateKeys(action, 'actions property', allowedKeys)
        }
        return valid
      }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    queryParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    TableSettings,
    TableActionBar,
    TableContent,
    TablePaginator
  },
  data () {
    return {
      loading: true,
      maxPageCount: 1,
      currentPageCount: 1,
      records: [],
      columnSelection: [],
      selected: [],
      currentRequest: null,
      rows: 15,
      requestCache: {},
      searchQuery: ''
    }
  },
  mounted () {
    this.validateSettingFields()
    this.initializeSelectedColumns()
    this.initialPageRequest()
  },
  methods: {

    // --- VALIDATIONS ------------------

    /** Validate whether the settingfields are also present in the column property as key values. */
    validateSettingFields () {
      const columnKeys = this.columns.map(column => column.key)
      for (const settingField of this.settingFields) {
        if (!columnKeys.includes(settingField)) {
          console.error(`settingsField '${settingField}' has no matching column`)
        }
      }
    },

    loadPage (url) {
      return new Promise((resolve) => {
        this.currentRequest = $.ajax({
          type: 'GET',
          url: url
        }).done(data => {
          // Always cache the loaded data
          this.requestCache[url] = data
          this.currentRequest = null
          this.maxPageCount = Math.ceil(data.count / this.rows)
          resolve()
        }).fail(err => {
          if (err.statusText !== 'abort') {
            console.error(`Unable to retrieve Table data for endpoint ${url}`)
          }
        })
      })
    },

    // -- PAGINATIONS ---------------------

    /** Go to the next page */
    toNextPage () {
      if (this.currentPageCount >= this.maxPageCount) {
        return
      }

      const url = this.nextPageUrl
      this.currentPageCount += 1

      // If page was cached
      const cached = this.setPageFromCache(url)
      if (!cached) {
        this.loading = true
        this.loadPage(url).then(() => {
          this.setPageFromCache(url)
          this.loading = false
        })
      }
    },

    /** Go to the previous page */
    toPreviousPage () {
      if (this.currentPageCount === 1) {
        return
      }

      const url = this.previousPageUrl
      this.currentPageCount -= 1

      // If page was cached
      const cached = this.setPageFromCache(url)
      if (!cached) {
        this.loading = true
        this.loadPage(url).then(() => {
          this.setPageFromCache(url)
          this.loading = false
        })
      }
    },

    /** Aborts the previous request if any. */
    abortPreviousRequest () {
      if (this.currentRequest) {
        this.currentRequest.abort()
        this.currentRequest = null
      }
    },

    /** Perform the first page retrieval or reinitializes the table */
    initialPageRequest () {
      this.loading = true
      this.currentPageCount = 1

      // Check if a request is running if so abort
      this.abortPreviousRequest()

      // If page was cached
      const setFromCache = this.setPageFromCache(this.currentPageUrl)
      if (setFromCache) return

      this.loadPage(this.currentPageUrl).then(() => {
        this.setPageFromCache(this.currentPageUrl)
      })
    },

    /** Go to a requested page */
    goToPage (pageNumber) {
      this.loading = true
      this.currentPageCount = parseInt(pageNumber)

      // Check if a request is running if so abort
      this.abortPreviousRequest()

      // If page was cached
      const setFromCache = this.setPageFromCache(this.currentPage)
      if (setFromCache) return

      // No, get page from database
      this.currentRequest = $.ajax({
        type: 'GET',
        url: this.currentPageUrl
      }).done(data => {
        this.requestCache[this.currentPageUrl] = data
        this.currentRequest = null
        this.maxPageCount = Math.ceil(data.count / this.rows) || 1
        this.records = data.results
        this.loading = false
      }).fail(err => {
        if (err.statusText !== 'abort') {
          console.error(`Unable to retrieve Table data for endpoint ${this.currentPage}${this.queryString}`)
        }
      })
    },

    // -- CACHING ----------------------------------------------

    /** Retrieves a table's page from cache if it was added to the cache due
     * to a previous pagination request and set the table data.
     *
     * @url{str}: The url for which we will check if it is cached
     * @returns{boolean}: True if the page was set from cache else false
     * */
    setPageFromCache (url) {
      // Check if page in cache
      const cachedData = this.requestCache[url]
      if (cachedData) {
        this.maxPageCount = Math.ceil(cachedData.count / this.rows)
        this.records = cachedData.results
        this.loading = false
      }

      if (!this.requestCache[this.nextPageUrl]) {
        this.loadPage(this.nextPageUrl)
      }
      if (!this.requestCache[this.previousPageUrl]) {
        this.loadPage(this.previousPageUrl)
      }

      return !!cachedData
    },

    // -- FILTERING -----------------------------------------------

    /** initializes all selected columns */
    initializeSelectedColumns () {
      this.columnSelection = this.columns
        .filter(column => column.visible !== false)
        .map(column => column.key)
    },

    // -- MODAL CONTROL ----------------------------------------

    /** Show the settings modal */
    showSettingsModal () {
      this.$refs.tableSettings.show()
    },

    // -- EMITS --------------------------------------------------
    emitAction (value) {
      this.$emit(value.actionName, value.actionRecord)
    },

    // -- ACTIONS ------------------------------------------------

    /** Deselect everything */
    clearSelected () {
      this.selected = []
    },

    /** Clears the cache (for outside control) **/
    clearCache () {
      this.requestCache = {}
    },
    refresh () {
      this.clearCache()
      this.initialPageRequest()
    }
  },
  computed: {

    /** Contains all columns after filtering */
    visibleColumns () {
      const columnsToBeHidden = []
      for (const settingField of this.settingFields) {
        if (!this.columnSelection.includes(settingField)) {
          columnsToBeHidden.push(settingField)
        }
      }

      const columns = []
      for (const column of this.columns) {
        if (!columnsToBeHidden.includes(column.key)) {
          columns.push(column)
        }
      }
      return columns
    },

    /** Contains all columns which are filterable */
    settingColumns () {
      return this.columns.filter(column => this.settingFields.includes(column.key))
    },

    /** An optional query string suffixes for the endpoint */
    queryString () {
      // Query param suffixes
      let queryString = ''
      for (const [key, value] of Object.entries(this.queryParams)) {
        if (Array.isArray(value)) {
          for (const item of value) {
            queryString += `&${key}=${item}`
          }
        } else {
          queryString += `&${key}=${value}`
        }
      }

      // Search query suffix
      if (this.searchQuery) {
        queryString += `&query=${this.searchQuery}`
      }

      return queryString
    },
    currentPageUrl () {
      return `${this.endpoint}?page=${this.currentPageCount}${this.queryString}`
    },
    previousPageUrl () {
      if (this.currentPageCount > 1) {
        return `${this.endpoint}?page=${this.currentPageCount - 1}${this.queryString}`
      }
      return null
    },
    nextPageUrl () {
      if (this.currentPageCount < this.maxPageCount) {
        return `${this.endpoint}?page=${this.currentPageCount + 1}${this.queryString}`
      }
      return null
    }
  },
  watch: {

    // -- RELOAD ON PROP CHANGES --------------------

    /** When we change the endpoint for a table, treat it as if the table is reinitialized.
     * We treat it as such because we dont know how many records will be returned for the new
     * endpoint. Reinitialization resets the page counts. */
    endpoint () {
      this.records = []
      this.selected = []
      this.initializeSelectedColumns()
      this.initialPageRequest()
    },
    /** When we change the query params for a table, treat it as if the table is reinitialized.
     * We treat it as such because we dont know how many records will be returned for the new
     * query params. Reinitialization resets the page counts. */
    queryParams () {
      if (this.queryParams) {
        this.selected = []
        this.initializeSelectedColumns()
        this.initialPageRequest()
      }
    },

    // -- V-MODEL -----------------------------------

    /** Selected records are v-modeled */
    selected () {
      this.$emit('input', this.selected)
    },

    value () {
      this.selected = this.value
    },
    // -- SEARCH QUERY ------------------------------

    /** Whenever we perform a search request, treat it as an initial request with new query param.
     * We treat it as new because we dont know how many responses we will get for the query.
     * Reinitialization resets the page counts. */
    async searchQuery () {
      await this.initialPageRequest()
    }
  }
}
</script>

<style scoped>

</style>
