<template>
  <div class="table-footer text-center">
    <div class="btn-group">
      <button @click="toPrevious"
              class="btn btn-white"
              :class="{'disabled': currentPage === 1}"
      >
        <i class="fa fa-chevron-left"></i> Vorige
      </button>

      <button class="btn btn-white">
        {{ currentPage > maxPage ? maxPage : currentPage }} / {{ maxPage }}
      </button>

      <button
        @click="toNext"
        :class="['btn btn-white', {'disabled': (currentPage === maxPage) || (currentPage > maxPage)}]"
      >
        Volgende <i class="fa fa-chevron-right"></i>
      </button>
    </div>

    <div style="display: inline-block; position: relative;top: 31px;">
      <div class="input-group m-b" style="width:150px;">
      <span class="input-group-btn">
        <button type="button"
                class="btn"
                @click="goToPage"
                :class="{
          'btn-primary':pageNumberValid,
          'btn-danger':!pageNumberValid
        }">
          <i class="fa fa-binoculars"></i>
        </button>
      </span>
        <input type="text"
               @keyup.enter="goToPage"
               placeholder="Pagina"
               v-model="pageNumber"
               class="form-control go-to">
      </div>
    </div>
    <span class="pull-right" style="position: relative; top:40px;">
      <span style="margin-right:10px; font-weight:bold; color: #1FA68A;" v-if="selectable">
        {{selected.length}} <i class="fa fa-check-square-o"
                               style="font-size:20px; color:#1FA68A; position:relative; top:2px;"></i>
      </span>
    </span>
  </div>
</template>

<script>

export default {
  name: 'TablePaginator',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    maxPage: {
      type: Number,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      pageNumber: null
    }
  },
  methods: {
    toPrevious () {
      if (this.currentPage !== 1) this.$emit('previous')
    },
    toNext () {
      if (this.currentPage !== this.maxPage) this.$emit('next')
    },
    goToPage () {
      if (this.pageNumberValid) {
        this.$emit('go-to', this.pageNumber)
        this.pageNumber = null
      }
    }
  },
  computed: {
    pageNumberValid () {
      if (this.pageNumber && this.pageNumber.length > 0) {
        const isInteger = Number.isInteger(parseInt(this.pageNumber))
        if (!isInteger) return false
        if (parseInt(this.pageNumber) >= 1 && parseInt(this.pageNumber) <= parseInt(this.maxPage)) {
          return true
        } else {
          return false
        }
      }
      return false
    }
  }
}
</script>

<style scoped>

  .spinner {
    color: #1FA68A;
    margin: 0px;
    padding: 0px;
    font-size: 18px;
  }

  .spinner-active {
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .go-to:focus, .go-to::selection {
    border-color: lightgrey !important;
  }

</style>
