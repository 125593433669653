<template>
  <component v-if="column.renderComponent"
             :is="column.renderComponent"
             :object="record"
  ></component>
  <span v-else>{{cellValue}}</span>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    column: {
      type: Object,
      required: true
    },
    record: {
      value: [Number, String, Object],
      required: true
    }
  },
  computed: {
    /** Retrieve the value for a cell */
    cellValue () {
      const keys = this.column?.key?.split('.')
      let subsetRecord = this.record
      if (keys) {
        keys.forEach(keyItem => {
          if (subsetRecord) subsetRecord = subsetRecord[keyItem]
        })
      }
      return subsetRecord
    }
  }
}
</script>

<style scoped>

</style>
