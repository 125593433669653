<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <table class="table table-hover">

    <!-- Table headers -->
    <thead>
    <tr>
      <th v-if="selectable && uniqueRecords.length > 0">
        <input @click="selectAllRecords" v-model="selectAllInput" type="checkbox">
      </th>
      <th :key="index"
          v-for="(column, index) in columns">{{ column.title }}
      </th>
      <th v-if="actions"></th>
    </tr>
    </thead>

    <!-- Table body -->
    <tbody>

    <template v-if="loading">
      <div style="width:300%">
        <Loader></Loader>
      </div>
    </template>
    <template>
      <tr :key="record.uuid"
          v-for="(record, index_record) in uniqueRecords">
        <td v-if="selectable" style="text-align: left">
          <input type="checkbox" v-model="selected" :value="record">
        </td>
        <td :key="index_record + index_column" v-for="(column, index_column) in columns">
          <table-cell :column="column"
                      :record="record"/>
        </td>
        <td v-if="actions">
          <div class="pull-right" :style="{width:actionsWidth}">
            <a :class="`btn-${action.type}`"
               :key="index"
               @click="actionHandler(action, record)"
               class="btn btn-xs"
               v-for="(action, index) in actions"
               v-if="recordHasConditionalKey(action, record)"
            >
              <i class="fa"
                 :class="`fa-${action.icon}`"
                 :title="action.title"></i>
              <template v-if="action.label">{{ action.label }}</template>
            </a>
          </div>
        </td>
      </tr>
    </template>

    </tbody>

  </table>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import { validateKeys } from '@/utils/helpers'

import TableCell from '@/components/iam/table/TableCell'
import Loader from '@/components/iam/Loader'

export default {
  name: 'TableContent',
  props: {
    value: {
      required: true,
      type: Array
    },
    records: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true,
      validator: columns => {
        let valid = true
        for (const column of columns) {
          const allowedKeys = ['title', 'key', 'visible', 'renderComponent']
          valid = validateKeys(column, 'columns property', allowedKeys)
        }
        return valid
      }
    },
    actions: {
      type: Array,
      required: false,
      validator: actions => {
        let valid
        for (const action of actions) {
          const allowedKeys = ['key', 'type', 'icon', 'title', 'conditionalKey', 'inverseCondition']
          valid = validateKeys(action, 'actions property', allowedKeys)
        }
        return valid
      }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selected: [],
      selectAllInput: false
    }
  },
  components: { Loader, TableCell },
  methods: {

    // --- OPERATIONAL FUNCTIONS ----------------------------

    /** This function checks if a key in a table record's data holds a truthy value.
     *
     * @param action{str}: The action containing the name for the key
     * @param record{obj}: The record (data) in which we will check if a key is truthy
     * */
    recordHasConditionalKey (action, record) {
      if (action.conditionalKey) {
        const conditionalKeyValue = record[action.conditionalKey]
        if (action.inverseCondition) {
          return !conditionalKeyValue
        } else {
          return !!conditionalKeyValue
        }
      } else {
        return true
      }
    },

    /** This method emits that an action has been clicked for a table record
     *
     * @param action {obj}: All action metadata
     * @param record {obj}: the record (data) for which the action is to be performed
     * */
    actionHandler (action, record) {
      this.$emit('action', { actionName: action.key, actionRecord: record })
    },

    /** Select or deselects all records for the current page */
    selectAllRecords (event) {
      if (event.target.checked) {
        const selectedIds = this.selected.map(selectedObj => selectedObj.id)
        for (const record of this.records) {
          if (!selectedIds.includes(record.id)) {
            this.selected.push(record)
          }
        }
      } else {
        const recordIds = this.records.map(record => record.id)
        this.selected = this.selected.filter(selectedObj => !recordIds.includes(selectedObj.id))
      }
    },

    /** Will turn on the select all checkbox if all records for the current page are
     * selected else it deselects the select all checkbox.
     * */
    toggleSelectAllInput () {
      const selectedIds = this.selected.map(selectedObj => selectedObj.id)
      const fullMatch = this.records.every(record => selectedIds.includes(record.id))
      if (fullMatch) {
        this.selectAllInput = true
      } else {
        this.selectAllInput = false
      }
    }
  },
  watch: {

    // -- VMODEL -------------------------------------

    /**
     * 1) Updates selected records for parent component
     * 2) Whenever we make a selection we need to reevaluate whether all
     *    records have been selected, if so turn all the select all checkbox else
     *    turn it off
     * */
    selected () {
      this.toggleSelectAllInput()
      this.$emit('input', this.selected)
    },
    /** Update selected records for current component from parent input */
    value () {
      this.selected = this.value
    },

    // -- SELECT ALL RESET -----------------------------

    /** Whenever the records change we enter a different page. We need to determine whether
     * all records for this page have been selected. If so, we turn the select all toggle on,
     * if not we turn it off. */
    records () {
      this.toggleSelectAllInput()
    }
  },
  computed: {
    actionsWidth () {
      return `${this.actions.length * 25}px`
    },
    /** Add a uuid to all records. This allows us to uniquely identify each record. */
    uniqueRecords () {
      return this.records.map(record => {
        return { ...record, uuid: uniqueId() }
      })
    }
  }
}
</script>
