<template>
  <Modal ref="modal" title="Instellingen voor tabel">
    <div>
      <h2>Zichtbare kolommen</h2>
      <div :key="index" v-for="(column, index) in columns">
        <input type="checkbox" :id="column.key" :value="column.key" v-model="selectedKeys">
        <label :for="column.key">{{column.title}}</label>
      </div>
    </div>
    <div slot="footer">
      <button @click="hide()" class="btn btn-primary" type="button">Sluiten</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal'
import { validateKeys } from '@/utils/helpers'

export default {
  name: 'TableSettings',
  components: { Modal },
  props: {
    value: {
      required: true,
      type: Array
    },
    columns: {
      type: Array,
      required: true,
      validator: columns => {
        let valid = true
        for (const column of columns) {
          const allowedKeys = ['title', 'key', 'visible', 'renderComponent']
          valid = validateKeys(column, 'columns property', allowedKeys)
        }
        return valid
      }
    }
  },
  mounted () {
    this.selectedKeys = this.columns.map(column => column.key)
  },
  data () {
    return {
      selectedKeys: []
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  },
  watch: {
    selectedKeys () {
      this.$emit('input', this.selectedKeys)
    },
    value () {
      this.selectedKeys = this.value
    }
  }
}
</script>

<style scoped>

</style>
